import React from 'react';

export default function Button({ label, className }) {
    const fontSize = 'text-xs lg:text-base';
    const textProperties = 'text-white text-center uppercase';
    const animationProperties = 'transition-all duration-500 ease-linear';
    const padding = 'py-3 md:py-4 px-7 md:px-8 lg:px-10 xl:px-12';
    const borderRadius = 'rounded-[72.5px]';
    const backgroundGradient = 'bg-gradient-to-tr to-default from-mi-purple';

    return (
        <button
            className={`${textProperties} ${animationProperties}
                  gap-5   ${padding} ${borderRadius} ${backgroundGradient}
                ${fontSize} ${className}
            `}
        >
            {label}
        </button>
    );
}
