import React from 'react';
import { Link } from 'gatsby';

import Button from '../components/atoms/inputs/Button';
import BaseLayout from '../components/templates/BaseLayout';

function NotFoundPage() {
    return (
        <BaseLayout>
            <div className="font-arial mx-auto w-full flex flex-col items-center justify-center h-full py-20">
                <h1 className="hidden">Page Not Found</h1>
                <h2 className="md:text-lg lg:text-xl mb-12">
                    Sorry we couldn't find what you were looking for.
                </h2>
                <Link to="/">
                    <Button label="Return Home" />
                </Link>
            </div>
        </BaseLayout>
    );
}

export default NotFoundPage;
